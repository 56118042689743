import React, { useState, useEffect, useContext } from "react"
import styled from "styled-components"

import { frealThemeContext } from "../../data/ThemeContext"
import { useTranslateValue } from "../../data/TranslateContext"

import PageWrapper from "../../styles/PageWrapper"
import Row from "../../styles/Row"
import SEO from "../../components/seo"

import EnglishMilkshakes from "./EnglishMilkshakes"
import FrenchMilkshakes from "./FrenchMilkshakes"
import EnglishSmoothies from "./EnglishSmoothies"
import FrenchSmoothies from "./FrenchSmoothies"

const FlavorsPageWrapper = styled(PageWrapper)`
  padding: 5rem 1rem;
  transition: all 0.2s ease-in-out;
`

const Wrapper = styled.div`
  > section {
    transition: all 200ms linear, height 0ms linear 200ms;

    height: 0;
    overflow: hidden;

    opacity: 0;
  }

  .section {
    &-enter {
      height: 0;

      &-done {
        height: auto;

        opacity: 1;
      }
    }
    &-exit {
      height: auto;

      &-done {
        height: 0;
      }
    }
  }
`

const PageTitle = styled.h1`
  text-align: center;
  font-size: 2.4em;
`

const FlavorsPage = function(props) {
  const themeContext = useContext(frealThemeContext)
  const [{ language }] = useTranslateValue()

  const [activeFlavor, setActiveFlavor] = useState(0)

  const incomingFlavor =
    props.location && props.location.state && props.location.state.flavor
      ? props.location.state.flavor
      : ""

  useEffect(() => {
    const timer = setTimeout(() => {
      themeContext.set({
        themeName: "milkshakes",
      })
    }, 300)

    return () => clearTimeout(timer)
  })

  return (
    <FlavorsPageWrapper>
      {language === "en" && (
        <>
          <SEO title="Flavors" />
          <PageTitle>The Flavors</PageTitle>
        </>
      )}
      {language === "fr" && (
        <>
          <SEO title="Les Saveurs" />
          <PageTitle>Les Saveurs</PageTitle>
        </>
      )}

      <Row>
        <Wrapper>
          {language === "en" && (
            <>
              <EnglishMilkshakes
                activeFlavor={activeFlavor}
                setActiveFlavor={setActiveFlavor}
                incomingFlavor={incomingFlavor}
              />
              <EnglishSmoothies
                activeFlavor={activeFlavor}
                setActiveFlavor={setActiveFlavor}
                incomingFlavor={incomingFlavor}
              />
            </>
          )}

          {language === "fr" && (
            <>
              <FrenchMilkshakes
                activeFlavor={activeFlavor}
                setActiveFlavor={setActiveFlavor}
                incomingFlavor={incomingFlavor}
              />
              <FrenchSmoothies
                activeFlavor={activeFlavor}
                setActiveFlavor={setActiveFlavor}
                incomingFlavor={incomingFlavor}
              />
            </>
          )}
        </Wrapper>
      </Row>
    </FlavorsPageWrapper>
  )
}

export default FlavorsPage
