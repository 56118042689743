export const strawberryBanana = {
  id: "smoothieStrawberryBanana",
  backgroundColor: "#8D1055",
  textColor: "#FFFFFF",
  name: "Smoothie Fraises et Bananes",
  tagline: "Smoothie Fraises et Bananes",
  description: false,
  ingredients:
    "Eau, Purée de fraises (fraises, sucre, sorbate de potassium), Sucres (sucre, fructose, dextrose), Purée de bananes, Acide citrique, Gomme de caroube, Méthylcellulose, Gomme de guar, Arômes naturels, Concentré de betterave.",
  disclaimer:
    "Peut contenir : Lait, Arachides, Noix, Soja, Blé, Œufs provenant de la fabrication et du mélange.",
  nutritionFacts: {
    servingSize: "Par 1 Contenant (237 mL/355 mL une fois préparé)",
    calories: "190",
    values: [
      {
        type: "primary",
        label: "Lipides",
        value: "0.1g",
        percent: "1%",
      },
      {
        type: "secondary",
        label: "Saturés<br>+ Trans",
        value: "0g<br>0g",
        percent: "0%",
      },
      {
        type: "primary",
        label: "Glucides",
        value: "46g",
        percent: "",
      },
      {
        type: "secondary",
        label: "Fibres",
        value: "2g",
        percent: "7%",
      },
      {
        type: "secondary",
        label: "Sucres",
        value: "44g",
        percent: "44%",
      },
      {
        type: "primary",
        label: "Protéines",
        value: "0.5g",
        percent: "",
      },
      {
        type: "primary",
        label: "Cholestérol",
        value: "0mg",
        percent: "0%",
      },
      {
        type: "primary",
        label: "Sodium",
        value: "5mg",
        percent: "1%",
      },
      {
        type: "primary",
        label: "Potassium",
        value: "75mg",
        percent: "2%",
      },
      {
        type: "primary",
        label: "Calcium",
        value: "10mg",
        percent: "1%",
      },
      {
        type: "primary",
        label: "Fer",
        value: "0.4mg",
        percent: "2%",
      },
    ],
  },
}

export const mango = {
  id: "smoothieMango",
  backgroundColor: "#FFA832",
  textColor: "#004E7D",
  name: "Smoothie Mangues",
  tagline: "Smoothie Mangues",
  description: false,
  ingredients:
    "Eau, Base de mangue (concentré de purée de mangue, eau, jus d’ananas concentré, acide citrique, arôme naturel, jus de citrouille concentré, rocou, sorbate de potassium), Sucres (sucre, dextrose, fructose), Gomme de caroube, Méthylcellulose, Gomme de guar, Arôme naturel, Acide citrique.",
  disclaimer:
    "Peut contenir : Lait, Arachides, Noix, Soja, Blé, Œufs provenant de la fabrication et du mélange.",
  nutritionFacts: {
    servingSize: "Par 1 Contenant (237 mL/355 mL une fois préparé)",
    calories: "190",
    values: [
      {
        type: "primary",
        label: "Lipides",
        value: "0.1g",
        percent: "1%",
      },
      {
        type: "secondary",
        label: "Saturés<br>+ Trans",
        value: "0g<br>0g",
        percent: "0%",
      },
      {
        type: "primary",
        label: "Glucides",
        value: "46g",
        percent: "",
      },
      {
        type: "secondary",
        label: "Fibres",
        value: "0g",
        percent: "0%",
      },
      {
        type: "secondary",
        label: "Sucres",
        value: "44g",
        percent: "44%",
      },
      {
        type: "primary",
        label: "Protéines",
        value: "0.3g",
        percent: "",
      },
      {
        type: "primary",
        label: "Cholestérol",
        value: "0mg",
        percent: "0%",
      },
      {
        type: "primary",
        label: "Sodium",
        value: "5mg",
        percent: "1%",
      },
      {
        type: "primary",
        label: "Potassium",
        value: "125mg",
        percent: "3%",
      },
      {
        type: "primary",
        label: "Calcium",
        value: "10mg",
        percent: "1%",
      },
      {
        type: "primary",
        label: "Fer",
        value: "0.1mg",
        percent: "1%",
      },
    ],
  },
}
