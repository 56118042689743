import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { CardRow, TypeTitle, TypeDescription } from "./Card"
import Flavor from "./Flavor"

import { strawberryBanana, mango } from "../../data/smoothiesDataFr"

export default function FrenchSmoothies(props) {
  const { activeFlavor, setActiveFlavor, incomingFlavor } = props

  const images = useStaticQuery(graphql`
    query flavorsSmoothiesFr {
      strawberryBananaFr: file(
        relativePath: {
          eq: "front-page-flavors/smoothie-strawberry-banana-can.png"
        }
      ) {
        childImageSharp {
          fixed(height: 300) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      mangoFr: file(
        relativePath: { eq: "front-page-flavors/smoothie-mango-can.png" }
      ) {
        childImageSharp {
          fixed(height: 300) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  return (
    <>
      <TypeTitle marginTop="5rem">Smoothies</TypeTitle>
      <TypeDescription>
        Fabriqués avec de vrais fruits, nos smoothies sont maintenant à base de
        plantes et à faible teneur en sucre ajouté!
      </TypeDescription>

      <CardRow>
        <Flavor
          data={strawberryBanana}
          fixed={images.strawberryBananaFr.childImageSharp.fixed}
          flavorIndex={7}
          activeFlavor={activeFlavor}
          setActiveFlavor={setActiveFlavor}
          incomingFlavor={incomingFlavor}
        />

        <Flavor
          data={mango}
          fixed={images.mangoFr.childImageSharp.fixed}
          flavorIndex={8}
          activeFlavor={activeFlavor}
          setActiveFlavor={setActiveFlavor}
          incomingFlavor={incomingFlavor}
        />
      </CardRow>
    </>
  )
}
